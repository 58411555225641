import './App.css';
import Web3 from 'web3'
import React, { Component } from 'react';
import NFT7432 from '../abis/NFT7432.json'

class App extends Component {
  async componentWillMount() {
    await this.loadWeb3()
    await this.loadBlockchainData()
  }

  async loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
    }
    else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    }
    else {
      window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
    }
  }

  async loadBlockchainData() {
    const web3 = window.web3
    // Load account
    const accounts = await web3.eth.getAccounts()
    this.setState({ account: accounts[0] })
    // Load smart contract: NFT7432
    try {
      const abi = NFT7432.abi
      const contractAddr = "0x0"
      this.setState({ contractAddr })
      const contract = new window.web3.eth.Contract(abi, contractAddr)
      this.setState({ contract })
    } catch (err) {
      window.alert(err)
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      list: [{
        reserve: [{ str: "", err: "", amount: "" }],
        buyers: [{ str: "", err: "" }],
        airdrop: [{ str: "", err: "" }],
        purchase: [{ str: "", err: "" }]
      }],
      account: '',
      contract: null,
      contractAddr: ''
    };
  }
  
  handleChange(i, e) {
    let list = this.state.list;
    if (e.target.name === "amount") {
      list[i]["reserve"]["amount"] = e.target.value;
    } else {
      list[i][e.target.name]["str"] = e.target.value;
      list[i][e.target.name]["err"] = "";
    }
    this.setState({ list });
  }

  edit(e) {
    // Reload smart contract: NFT7432
    try {
      const abi = NFT7432.abi
      const contractAddr = e.target.value
      this.setState({ contractAddr })
      const contract = new window.web3.eth.Contract(abi, contractAddr)
      this.setState({ contract })
    } catch (err) {
      window.alert(err)
    }
  }

  reserveNFT7432() {
    const web3 = window.web3
    const toAcc = this.state.list[0].reserve.str
    const amount = this.state.list[0].reserve.amount
    let options = {
      from: this.state.account
    }
    
    web3.eth.getBlock("latest", false, (error, result) => {
      this.state.contract.methods.reserveNFT7432(toAcc, 1)
        .estimateGas(options)
        .then((costPerToken) => {
          const maxAmount = Math.floor(result.gasLimit / costPerToken)
          if (amount > maxAmount) {
            window.alert("Maximum gas limit exceeded, you can only reserve " +  maxAmount + " tokens at a time.")
          }
          else {
            const contractMethod = this.state.contract.methods.reserveNFT7432(toAcc, amount)
            contractMethod.estimateGas(options)
              .then((methodGasEstimate) => {
                options = {
                  ...options,
                  gas: parseInt(1.2 * methodGasEstimate)
                }
                contractMethod.send(options)
                  .catch((err) => {
                    console.log(err.message)
                  })
            })
          }
        })
    })
  }

  addPreSaleBuyer() {
    let buyer_list = this.state.list[0].buyers.str.replace(/\s/g, '').split(',')
    this.state.contract.methods.addPreSaleBuyer(buyer_list)
      .send({ from: this.state.account })
      .catch((err) => {
        console.log(err.message)
      })
  }

  launch() {
    this.state.contract.methods.launch()
      .send({ from: this.state.account })
      .catch((err) => {
        console.log(err.message)
      })
  }

  withdrawAll() {
    this.state.contract.methods.withdrawAll()
      .send({ from: this.state.account })
      .catch((err) => {
        console.log(err.message)
      })
  }

  airdrop() {
    const web3 = window.web3
    let airdrop_list = this.state.list[0].airdrop.str.replace(/\s/g, '').split(',')
    let options = {
      from: this.state.account
    }
  
    web3.eth.getBlock("latest", false, (error, result) => {
      this.state.contract.methods.airdrop([airdrop_list[0]])
        .estimateGas(options)
        .then((costPerToken) => {
          const maxAmount = Math.floor(result.gasLimit / costPerToken)
          if (airdrop_list.length > maxAmount) {
            window.alert("Maximum gas limit exceeded, you can only reserve " + maxAmount + " tokens at a time.")
          }
          else {
            const contractMethod = this.state.contract.methods.airdrop(airdrop_list)
            contractMethod.estimateGas(options)
              .then((methodGasEstimate) => {
                options = {
                  ...options,
                  gas: parseInt(1.2 * methodGasEstimate)
                }
                contractMethod.send(options)
                  .catch((err) => {
                    console.log(err.message)
                  })
              })
          }
        })
    })
  }

  pause() {
    this.state.contract.methods.pause()
      .send({ from: this.state.account })
      .catch((err) => {
        console.log(err.message)
      })
  }

  resume() {
    this.state.contract.methods.resume()
      .send({ from: this.state.account })
      .catch((err) => {
        console.log(err.message)
      })
  }

  //--------------------------- for demonstration ---------------------------//
  purchase() {
    let amount = this.state.list[0].purchase.str.replace(/\s/g, '').split(',')
    this.state.contract.methods.purchase(amount)
      .send({
        from: this.state.account,
        value: window.web3.utils.toWei((0.09 * amount).toString(), 'ether')
      })
      .catch((err) => {
        console.log(err.message)
      })
  }
  //--------------------------- for demonstration ---------------------------//

  render() {
    return (
      <div class="row input-area">
        <div className="col-md-4 left">
          <p className="title"> NFT7432 TOOLS </p>

          {/* contract address */}
          <div className="container">
          <div className="row contractAddr">
            <div className="col-sm-12">
              <label className="text"> contract address: </label>
              <input className="underline" value={this.state.contractAddr} onChange={e => this.edit(e)}></input>
              {/* <span className="glyphicon glyphicon-edit"></span> */}
            </div>
          </div>
          </div>

          {/* launch */}
          <div className="container">
          <div className="row">
            <div className="col-md-4">
            <form onSubmit={(event) => {
              event.preventDefault()
              this.launch()
            }}>
              <input type='submit' className="button" value='LAUNCH'/>
            </form>
            </div>

            {/* withdrawAll */}
            <div className="col-md-2">
              <form onSubmit={(event) => {
                event.preventDefault()
                this.withdrawAll()
              }}>
                <input type='submit' className="button withdraw" value='WITHDRAW'/>
              </form>
            </div>
          </div>
          </div>
    
          {/* Reserve */}
          <form onSubmit={(event) => {
            event.preventDefault()
            this.reserveNFT7432(this.state.list)
          }}>
          
          <div className="container">
          <div className="row">
            <div className="col-md-4">
              <label className="text input-field"> Address: </label>
              {this.state.list.map((element, index) => (
                <input type="text" className="form-control input-field-2" placeholder='e.g. 0x43212eB37BE18fF28A58D47186aF9Db9650bF341' name="reserve" value={element.reserve.str || ""} onChange={e => this.handleChange(index, e)} />
              ))}
            </div>
              
            <div className="col-md-1">
              <label className="text input-field"> Amount: </label>
              {this.state.list.map((element, index) => (
                <input className="form-control input-field-2" name="amount" placeholder='e.g. 1' value={ this.state.list[0].reserve.amount || "" } onChange={ e => this.handleChange(index, e) }></input>
              ))}
                </div>
          </div>
          
          <div className="row">
            <div className="col-md-4">
              <input type='submit' className="button" value='RESERVE' />
            </div>
          </div>
          
          <div className="row">
            <div className="col-md-4">
                <hr className="input-field"></hr>
            </div>
          </div>
          </div>
            
          </form>

          {/* add pre-sale buyer to the white list */}
          <form onSubmit={(event) => {
            event.preventDefault()
            this.addPreSaleBuyer(this.state.list)
          }}>
              
          <div className="container">
          <div className="row">
            <div className="col-md-4">
              <label className="text input-field"> Pre-Sale list: </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              {this.state.list.map((element, index) => (
                <input type="text" className="form-control input-field"
                      placeholder='e.g. 0x24f70f03D6a4BA301Aa936c18c604dbC68248081,0x70B1CAC27924b3BBdF05bE0615B73De099404CaB'
                      name="buyers" value={element.buyers.str || ""}
                      onChange={e => this.handleChange(index, e)}
                />
              ))}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <input type='submit' className= "button" value='ADD' />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
                <hr className="input-field"></hr>
            </div>
          </div>
          </div>

          </form>

          {/* airdrop */}
          <form onSubmit={(event) => {
            event.preventDefault()
            this.airdrop()
          }}>

          <div className="container">
          <div className="row">
            <div className="col-md-4">
              <label className="text input-field"> Airdrop list: </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              {this.state.list.map((element, index) => (
                <input type="text" className="form-control input-field"
                       placeholder='e.g. 0x24f70f03D6a4BA301Aa936c18c604dbC68248081,0x70B1CAC27924b3BBdF05bE0615B73De099404CaB'
                       name="airdrop" value={element.airdrop.str || ""}
                       onChange={e => this.handleChange(index, e)}
                />
              ))}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <input type='submit' className="button" value='AIRDROP'/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
                <hr className="input-field"></hr>
            </div>
          </div>
          </div>
              
          </form>

          {/*********************************** for demonstration ************************************/}
          <form onSubmit={(event) => {
            event.preventDefault()
            this.purchase()
          }}>

          <div className="container">
          <div className="row">
            <div className="col-md-4">
              <label className="text input-field"> Amount: </label>
              {this.state.list.map((element, index) => (
                <input type="text" className="form-control input-field" placeholder='e.g. 1' name="purchase" value={element.purchase.str || ""} onChange={e => this.handleChange(index, e)} />
              ))}
              <input type='submit' className="button" value='PURCHASE' />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
                <hr className="input-field"></hr>
            </div>
          </div>
          </div>
          
          </form>
          {/*********************************** for demonstration ************************************/}
        
        <div className="container">
        <div className="row">
          {/* pause */}
          <div className="col-md-1">
          <form onSubmit={(event) => {
            event.preventDefault()
            this.pause()
          }}>
            <input type='submit' className="button pause" value='PAUSE'/>
          </form>
          </div>
        
          {/* resume */}
          <div className="col-md-2">
            <form onSubmit={(event) => {
              event.preventDefault()
              this.resume()
            }}>
              <input type='submit' className="button" value='RESUME'/>
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 warn">
            warning: pause & resume button should be only used under suspicous condition.
          </div>
        </div>
        </div>
        
        </div>

        <div className="col-md-9">
        </div>
      </div>
    );
    }
}

export default App;